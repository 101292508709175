import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import Header from '../component/Header'
import Footer from '../component/Footer'

import axios from "axios"
import { API_URL, getHeader } from "../config"
export const form = async (formData, header) => {
    try {
      console.log('Sending request to:', `${API_URL}/api/form/${formData.schoolcode}`);
      console.log('Form data:', formData);
      console.log('Header:', header);
  
      const { data } = await axios.post(`${API_URL}/api/form/${formData.schoolcode}`, formData, { headers: header });
      console.log('Response data:', data);
      return data;
    } catch (error) {
      console.error('Error in form submission:', error);
      if (error.response) {
        console.error('Error response data:', error.response.data);
        console.error('Error response status:', error.response.status);
      }
      throw error;
    }
  };
const Contact = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
        schoolcode: "SGSM" // or other school code
      });
      const [errors, setErrors] = useState({});
      const [submitted, setSubmitted] = useState(false);
    
      const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value
        });
      };
    
      const validate = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = "Name is required";
        if (!formData.email) {
          newErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
          newErrors.email = "Email is invalid";
        }
        if (!formData.message) newErrors.message = "Message is required";
        return newErrors;
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
          setErrors(validationErrors);
          return;
        }
        try {
          const header = getHeader();
          console.log('Submitting form with data:', formData);
          const response = await form(formData, header);
          console.log('Form submitted successfully:', response);
          setSubmitted(true);
          setFormData({ name: "", email: "", message: "", schoolcode: formData.schoolcode });
          setErrors({});
        } catch (error) {
          console.error('Error submitting form:', error);
        }
      };
    return (
        <>
            <Header />
            <div className="breadcrumb">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex">
                                <h2>Contact Us</h2>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>|</li>
                                    <li>Contact</li>
                                    <li>|</li>
                                    <li>Contact Us</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="inner-section">
                <div className="container">
                    <div className='row'>
                        <div className='col-md-12'>
                            <h3 className="inner-main-title"><span className="dark-bg">Contact </span> <span className="light-bg">Us</span></h3>
                        </div>
                        <div className='col-md-6'>
                            <div className='contact-blk'>
                                <div><i class="bi bi-geo-alt-fill"></i> 
                                St. George’s College Barlowganj, Mussoorie - 248122, Uttarakhand (India)
                                </div>
                                <div><i class="bi bi-envelope-fill"></i>
                                principal@sgconline.ac.in
                                </div>
                                <div><i class="bi bi-telephone-fill"></i>
                                +91 7060311771 | 7060311770
                                </div>
                                <div>
                                <i class="bi bi-globe"></i>
                                www.sgconline.ac.in
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 contact-form'>
                        <form onSubmit={handleSubmit}>
            <div className="footerform">
            {submitted && <p className="success-message">Thank you for your message!</p>}
              <h3>Keep in touch</h3>
              <div className="form-group">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Your Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
                {errors.name && <p className="error">{errors.name}</p>}
              </div>
              <div className="form-group">
              <label>Email Id</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email Address"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && <p className="error">{errors.email}</p>}
              </div>
              <div className="form-group">
              <label>Message</label>
                <textarea
                  className="form-control"
                  placeholder="Message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
                {errors.message && <p className="error">{errors.message}</p>}
              </div>
              <div className="form-group">
                <input type="submit" value="SUBMIT" />
              </div>
            </div>
          </form>
                       </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Contact