import React from "react";
import Header from '../component/Header'
import Footer from '../component/Footer'
import { Link } from 'react-router-dom'

const Newsletter = () => {
    return(
        <>
            <Header />
      <div className="breadcrumb">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex">
                <h2>NewsLetter</h2>
                <ul>
                  <li><Link to="/">Home</Link></li>
                  <li>|</li>
                  <li>Gallery</li>
                  <li>|</li>
                  <li>E-NewsLetter </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="inner-section newsletter">
                <div className="container">
                    <div className="row">
                    <div className='col-md-12'>
                            <h3 className="inner-main-title"><span className="dark-bg">E - </span> <span className="light-bg">  Newsletter  </span></h3>
                        </div>
                        <div className='col-lg-4 col-md-6'>
                           <div className="card">
                            <Link to="https://drive.google.com/file/d/1NijST1hiWjWgBWGdJ_udSaifTMOdM7lZ/view?usp=drive_link" target="_blank">
                            <img src="/e-magazine1.JPG" className="img-fluid" alt="St. George’s College"  />
                            <h3>Manor Musings Edition 1</h3>
                            </Link>
                           </div>
                        </div>
                        <div className='col-lg-4 col-md-6'>
                           <div className="card">
                            <Link to="https://drive.google.com/file/d/11Og5fLWOBoYnDqINrhbr2s4s8YS_e7te/view?usp=drive_link"  target="_blank">
                            <img src="/newsletter2.jpg" className="img-fluid" alt="St. George’s College"  />
                            <h3>The Battle Of Houses</h3>
                            </Link>
                           </div>
                        </div>
                        <div className='col-lg-4 col-md-6'>
                           <div className="card">
                            <Link to="https://drive.google.com/file/d/1q4DEKOQvoc86ZDEQNWHwyROGDlb0JmBh/view?usp=drive_link"  target="_blank">
                            <img src="/newsletter3.jpg" className="img-fluid" alt="St. George’s College"  />
                            <h3>Manor Musings June Edition 2024</h3>
                            </Link>
                           </div>
                        </div>
                        <div className='col-lg-4 col-md-6'>
                           <div className="card">
                            <Link to="https://drive.google.com/file/d/1J7byi1pkxP6dgr6Nu7-WLJEexM42e8sw/view?usp=drive_link"  target="_blank">
                            <img src="/newsletter7.jpg" className="img-fluid" alt="St. George’s College"  />
                            <h3>Parents' Handbook 2024</h3>
                            </Link>
                           </div>
                        </div>
                        <div className='col-lg-4 col-md-6'>
                           <div className="card">
                            <Link to="https://drive.google.com/file/d/1dPAtpr28PI7s_cmHb-dt3V1irpgGUK1v/view?usp=drive_link"  target="_blank">
                            <img src="/newsletter5.jpg" className="img-fluid" alt="St. George’s College"  />
                            <h3>Manor Musings 2023</h3>
                            </Link>
                           </div>
                        </div>
                        <div className='col-lg-4 col-md-6'>
                           <div className="card">
                            <Link to="https://drive.google.com/file/d/1IYdOrktJ0mxl8FijSdT0_Fix_MY_sFkK/view?usp=drive_link"  target="_blank">
                            <img src="/newsletter6.jpg" className="img-fluid" alt="St. George’s College"  />
                            <h3>The Manorite</h3>
                            </Link>
                           </div>
                        </div>
                        <div className='col-lg-4 col-md-6'>
                           <div className="card">
                            <Link to="https://drive.google.com/file/d/1ubpEQT2RlUKl8zelq0S7ITs4Jj_xHEO4/view?usp=drive_link"  target="_blank">
                            <img src="/newsletter8.jpg" className="img-fluid" alt="St. George’s College"  />
                            <h3>The Manor Times</h3>
                            </Link>
                           </div>
                        </div>
                        
                    </div>
                </div>
            </div>
      <Footer />
        </>
    )
}
export default Newsletter